@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif !important;
}

body {
  background: #fff;
  color: #000;
}

a {
  color: #8cc63f !important;
  text-decoration: none;
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/
*::-webkit-scrollbar {
  width: 9px;
  display: none;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 10px;
  border: 2px solid var(--white);
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/

/*--------------------- 
        DateRange css 
----------------------------------*/

.date_range button {
  background: transparent;
  color: #000;
  font-weight: 400;
  border: 1.5px solid #c4c4c4 !important;
  border-radius: 4px;
  padding: 10px;
  text-align: start;
  font-size: 15px;
  width: 100%;
  /* box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, .15); */
}

.date_range_width button {
  width: unset !important;
  background: #8cc63f !important;
  color: #fff !important;
}

.daterangepicker .ranges li.active {
  background-color: #8cc63f !important;
}

.daterangepicker.opensright:before,
.daterangepicker.opensright:after {
  right: 20px !important;
  left: unset !important;
  display: none !important;
}

.show-calendar {
  right: 20px !important;
  overflow: scroll;
  height: 303px;
}

@media (max-width:563px) {
  .daterangepicker {
    right: 20px !important;
  }
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #8cc63f !important;
}

.applyBtn {
  background: #8cc63f !important;
  border: 1px solid #8cc63f !important;
}

.daterangepicker td.in-range {
  background-color: #eeeeee !important;
}

.daterangepicker td.end-date {
  background-color: #8cc63f !important;
}

/*--------------------- 
        DateRange css 
----------------------------------*/

.active_sidebar:hover {
  background: #8cc63f !important;
}

.active_sidebar .MuiTypography-root {
  color: #fff !important;
}

.form_select .MuiFormControlLabel-label {
  font-size: 14px;
}

.form_select .MuiRadio-root {
  padding: 5px;
}

.MuiFormControlLabel-root .MuiTypography-root {
  font-size: 14px;
}

.markeble_image img {
  width: 300px;
}

.sc-kEjbdu {
  display: none;
}

@media (max-width:600px) {
  .MuiCheckbox-root .MuiSvgIcon-root {
    width: 0.7em !important;
    height: 0.7em !important;
  }

  .MuiTableCell-root,
  .MuiFormControlLabel-label {
    font-size: 12px !important;
  }

  /* .makeStyles-cardContainer-2, */
  .MuiTableCell-root {
    padding: 8px 10px !important;
  }
}

.MuiMenu-paper {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

.login_button .Mui-disabled {
  background-color: #8cc63f !important;
}

.prostate_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 500px;
  gap: 5px;
}

.prostate_top_left {
  grid-column: 1/3;
}

.prostate_top_right {
  grid-column: 3/5;
}

.prostate_grid div {
  border: 3px dashed transparent;
}

.active_prostate {
  border: 3px dashed #8cc63f !important;
  filter: grayscale(100%);
}